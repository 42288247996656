








import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import { AdminRoutes } from '@/shared/router/admin'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import RiskTemplateForm from "@/admin/components/risk-templates/RiskTemplateForm.vue";
import HseRiskTemplate from "@/shared/modules/hse-risk-templates/hse-risk-templates.model";
import { getLanguagesByVersion, getNodeApiUrlByVersion } from "@/config";
import localeHelper from "@/shared/helpers/locale.helper";
import ArrayField from "@/shared/classes/form/fields/array-field";
import { getVersion } from "@/shared/helpers/version.helper";

@Component({
  components: { RiskTemplateForm, PageHeader },
  methods: { __ }
})
export default class RiskTemplateEdit extends Vue {
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setUuid(this.$router.currentRoute.params.uuid)
      .setModel(HseRiskTemplate)
      .setEndpoint(`${getNodeApiUrlByVersion()}/hse-risk-templates`)
      .setMethod(HttpMethod.PATCH)
      .setTranslatable(true)
      .setCurrentLanguage(getLanguagesByVersion([localeHelper.getLocale()])[0])
      .setFields([
        new SearchableField()
          .setKey("hseRiskCategoryUuid")
          .setEntryKey("hseRiskCategoryUuid")
          .isRequired()
          .setTitle(__("admin.components.risk-templates.form.category"))
          .loadItems({
            endpoint: `${getNodeApiUrlByVersion()}/hse-risk-categories`,
            value: "uuid",
            title: "name",
            perPage: 20
          })
          .setSize(FieldSizes.half),
        new SelectField()
          .setKey("isVisible")
          .setItems([
            new SelectItem()
              .setValue(true)
              .setTitle(__("admin.components.risk-templates.form.visible-option")),
            new SelectItem()
              .setValue(false)
              .setTitle(__("admin.components.risk-templates.form.invisible-option")),
          ])
          .setSize(FieldSizes.half)
          .setTitle(__("admin.components.risk-templates.form.visibility")),
        new Field()
          .setType(FieldTypes.textArea)
          .setKey(`name.${localeHelper.getLocale()}`)
          .setTitle(__("admin.components.risk-templates.form.risk-title"))
          .setSize(FieldSizes.half)
          .setRequired(localeHelper.getLocale() === getVersion()),
        new Field()
          .setType(FieldTypes.textArea)
          .setKey("name")
          .setTranslatable(true)
          .setTitle(__("admin.components.risk-templates.form.risk-title"))
          .setSize(FieldSizes.half)
          .setRequired(localeHelper.getLocale() !== getVersion()),
        new ArrayField()
          .setKey("barriers")
          .setDense(true)
          .setDeleteButtonProps({ color: "transparent", elevation: 0, class: "mt-5" })
          .setAddButtonProps({ isIcon: true, class: "justify-end mr-12" })
          .setJustifyChildren("justify-space-between")
          .setChildren([
            new Field()
              .setType(FieldTypes.textArea)
              .setKey(`title.${localeHelper.getLocale()}`)
              .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
              .setClasses("ml-4")
              .setSize(FieldSizes.half)
              .setRequired(localeHelper.getLocale() === getVersion()),
            new Field()
              .setType(FieldTypes.textArea)
              .setKey("title")
              .setTranslatable(true)
              .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
              .setClasses("ml-4")
              .setSize(FieldSizes.half)
              .setRequired(localeHelper.getLocale() !== getVersion()),
          ]),
      ])
      .setSubmit({
        text: __("general.update")
      })
      .setValidate(true, { onlyRegionLangRequired: true })
      .setShowDefaultValidationError(true)
      .setOnSuccess(this.onSuccess)
  }

  goBack(): void {
    this.$router.push({ name: AdminRoutes.hseRiskTemplatesIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('admin.views.hse.risk-templates.edit.form.on-success'),
    })
    this.goBack()
  }
}
